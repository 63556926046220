import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import FormLabelCustom from "components/FormLabel";
import FormRemoteSelectInput, {
  SelectOptionType,
} from "components/FormRemoteSelectInput";
import InputForm from "components/InputForm";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  createAccount,
  findAllParticipants,
  updateAccount,
} from "services/api.service";
import { validCnpj, validCpf } from "services/validDocument";
import { onlyNumbers } from "utils/number";
import { chaveSchema } from "views/admin/withdrawals/components/EditWithdrawel";
export interface CreateAccountCustomerForm {
  id?: string;
  bankIspb: SelectOptionType;
  agency: string;
  account: string;
  pix?: string;
  name?: string;
  document?: string;
}

export interface CreateAccountCustomerParams {
  id?: string;
  bankIspb: string;
  agency: string;
  account: string;
  pix?: string;
  accountType: "DICT" | "MANU";
  name?: string;
  document?: string;
}

const CreateAccountCustomer: React.FC<{
  isOpen: boolean;
  data?: CreateAccountCustomerParams;
  customerId: string;
  onClose: () => void;
}> = ({ data, onClose, isOpen, customerId }) => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const [type, setType] = React.useState<"DICT" | "MANU">(
    data?.accountType ?? "DICT"
  );

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CreateAccountCustomerForm>({
    defaultValues: {
      account: data?.account,
      agency: data?.agency,
      bankIspb: { value: data?.bankIspb, label: data?.bankIspb },
      id: data?.id,
      pix: data?.pix,
      name: data?.name,
      document: data?.document,
    },
  });

  const onSubmit = (dataRequest: CreateAccountCustomerForm) => {
    setLoading(true);
    const pix = dataRequest.pix ?? "";
    if (data?.id) {
      const document = onlyNumbers(dataRequest.document ?? "");
      updateAccount(customerId, data?.id, {
        ...dataRequest,
        bankIspb: dataRequest.bankIspb?.value,
        type: type,
        pix,
        document,
      })
        .then(() => {
          toast({
            title: "Succeso",
            description: `Conta Bancária Atualizada com Sucesso`,
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          setLoading(false);
          onClose();
        })
        .catch((err) => {
          setLoading(false);
          toast({
            title: "Error",
            description: err.response.data?.message,
            status: "warning",
            duration: 9000,
            isClosable: true,
          });
        });
    } else {
      createAccount(customerId, {
        ...dataRequest,
        bankIspb: dataRequest.bankIspb?.value,
        type: type,
        pix,
      })
        .then(() => {
          toast({
            title: "Succeso",
            description: `Conta Bancária Criada com Sucesso`,
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          setLoading(false);
          onClose();
        })
        .catch((err) => {
          setLoading(false);
          toast({
            title: "Error",
            description: err.response.data?.message,
            status: "warning",
            duration: 9000,
            isClosable: true,
          });
        });
    }
  };

  useEffect(() => {
    setType(data?.accountType ?? "DICT");
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Criar Conta Bancária</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={5} alignItems="start">
              <Text fontWeight="semibold">Dados bancários</Text>
              <FormLabelCustom
                label="Tipo de transfêrencia"
                props={undefined}
                name={""}
              />
              <Select
                onChange={(e) => {
                  setType(e.target.value as any);
                }}
                disabled={!!data?.id}
                value={type}
              >
                <option value="DICT">Chave Pix</option>
                <option value="MANU">Dados manuais</option>
              </Select>

              {data?.id && (
                <>
                  <InputForm
                    control={control}
                    label="Nome"
                    placeholder="Nome da conta"
                    name="name"
                    type="text"
                    rules={{
                      required: true,
                    }}
                  ></InputForm>

                  <InputForm
                    control={control}
                    label="CPF/CNPJ"
                    placeholder="000.000.000-00"
                    name="document"
                    type="tel"
                    rules={{
                      required: true,
                      validate: (value) => {
                        value = onlyNumbers(value);
                        if (validCnpj(value)) return true;
                        if (validCpf(value)) return true;
                        return "Documento não é válido";
                      },
                    }}
                  ></InputForm>
                </>
              )}

              {type === "DICT" && (
                <>
                  <Text fontWeight="bold">
                    A chave deve pertencer ao mesmo CNPJ do cadastro.
                  </Text>
                  <FormControl isInvalid={!!errors.pix?.message}>
                    <FormLabel display="flex" ms="4px" fontSize="sm" mb="8px">
                      Chave Pix (Opcional)
                    </FormLabel>
                    <Input
                      variant="auth"
                      fontSize="sm"
                      name="pix"
                      {...register("pix", {
                        required: "Esse campo é obrigatório",
                        validate: (value) => {
                          if (!chaveSchema.safeParse(value).success) {
                            return "Chave inválida";
                          }
                        },
                      })}
                      ms={{ base: "0px", md: "0px" }}
                      type="text"
                      placeholder="XXXXXXXXXXXXXXXXXXXX"
                    />
                    <FormErrorMessage>{errors.pix?.message}</FormErrorMessage>
                  </FormControl>
                </>
              )}

              {type === "MANU" && (
                <>
                  <FormRemoteSelectInput
                    control={control}
                    label="Instituição financeira"
                    required
                    registerOptions={{
                      required: "Esse campo é obrigatório",
                    }}
                    loadDataFn={({ value, cb }) =>
                      findAllParticipants({ filter: value }).then((retorno) => {
                        if (retorno?.registers?.length > 0) {
                          cb(
                            retorno.registers?.map((d: any) => ({
                              label: d.reducedName,
                              value: d.ispb,
                            }))
                          );
                        } else {
                          cb([]);
                        }
                      })
                    }
                    name="bankIspb"
                  />
                  <FormControl isInvalid={!!errors.agency?.message}>
                    <FormLabel display="flex" ms="4px" fontSize="sm" mb="8px">
                      Agência<Text>*</Text>
                    </FormLabel>
                    <Input
                      isRequired={true}
                      variant="auth"
                      fontSize="sm"
                      maxLength={4}
                      name="agency"
                      {...register("agency", {
                        minLength: {
                          message: "Esse campo deve ter no mínimo 4 caracteres",
                          value: 4,
                        },
                        maxLength: {
                          message: "Esse campo deve ter no máximo 4 caracteres",
                          value: 4,
                        },
                      })}
                      ms={{ base: "0px", md: "0px" }}
                      type="number"
                      placeholder="0001"
                      required
                    />
                    <FormErrorMessage>
                      {errors.agency?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!errors.account?.message}>
                    <FormLabel display="flex" ms="4px" fontSize="sm" mb="8px">
                      Conta (com dígito)<Text>*</Text>
                    </FormLabel>
                    <Input
                      isRequired={true}
                      variant="auth"
                      fontSize="sm"
                      name="account"
                      {...register("account", {
                        required: "Esse Campo é Obrigatório",
                      })}
                      ms={{ base: "0px", md: "0px" }}
                      type="tel"
                      placeholder="00101"
                    />
                    <FormErrorMessage>
                      {errors.account?.message}
                    </FormErrorMessage>
                  </FormControl>
                </>
              )}
            </VStack>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="brand"
            isLoading={loading}
            type="submit"
            onClick={() => handleSubmit(onSubmit)()}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateAccountCustomer;
