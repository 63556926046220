// Chakra imports
import { Box, ButtonGroup, SimpleGrid } from "@chakra-ui/react";

import { useMemo } from "react";
import { findAllBalancesPending } from "services/api.service";
import { Column } from "react-table";
import { DateTime } from "luxon";
import TableComponent from "components/TableComponent";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { maskCurrency } from "utils/number";
import ShowBalance from "./components/ShowBalance";
import { getVariable } from "whitelabel";

export interface Balance {
  amount: string;
  amountRefunded: string;
  createdAt: Date;
  id: string;
  fee: string;
  released: boolean;
  releasedOn?: string;
  type: string;
  referenceId: string;
  Customer: {
    id: string;
    name: string;
  };
}

export const TypeRender = (type: string) => {
  if (type === "LOCK") return "Bloqueio";
  if (type === "FEE") return "Taxa";
  if (type === "MOVEMENT") return "Venda";
  if (type === "MOVEMENT_MAIN") return "Venda Cliente";
  if (type === "INSURANCE") return "Seguro";
  if (type === "SYSTEM_FEE") return "Taxa " + getVariable("name");
  if (type === "SPLIT") return "Split";
  if (type === "CUSTOMER_SPLIT") return "Split do Cliente";
  if (type === "WITHDRAWAL") return "Saque";
  if (type === "WITHDRAWAL_ANTECIPATION") return "Antecipação de Recebíveis";
  if (type === "TRANSFER") return "Transferência";
  if (type === "CARD_RECHARGE") return "Recarga Cartão";
};

export enum BalanceTypeDescription {
  LOCK = "Bloqueio",
  FEE = "Taxa",
  MOVEMENT = "Venda",
  INSURANCE = "Seguro",
  MOVEMENT_MAIN = "Venda Cliente",
  SYSTEM_FEE = "Taxa",
  CARD_RECHARGE = "Recarga Cartão",
  SPLIT = "Split",
  CUSTOMER_SPLIT = "Split do Cliente",
  WITHDRAWAL = "Saque",
  WITHDRAWAL_ANTECIPATION = "Antecipação de Recebíveis",
  TRANSFER = "Transferência",
  DEPOSIT = "Depósito",
}

export default function BalancesPending() {
  const columns = useMemo(
    () =>
      [
        {
          Header: "Cliente",
          accessor: "Customer.name",
        },
        {
          Header: "Tipo",
          accessor: "type",
          filter: true,
          values: Object.keys(BalanceTypeDescription).map((key) => ({
            value: key,
            label: BalanceTypeDescription[key],
          })),

          Cell: ({ value }) => {
            return BalanceTypeDescription[value];
          },
        },
        {
          Header: "Valor Líquido",
          accessor: "amount",
          Cell: ({ value }) => {
            return maskCurrency(value);
          },
        },
        {
          Header: "Valor Devolvido",
          accessor: "amountRefunded",
          Cell: ({ value }) => {
            return maskCurrency(value);
          },
        },
        {
          Header: "Taxa/Liberado",
          accessor: "fee",
          Cell: ({ value }) => {
            return maskCurrency(value);
          },
        },

        {
          Header: "Liberado",
          accessor: "released",
          Cell: ({ value }) => {
            return value ? (
              <CheckIcon color="green.500" />
            ) : (
              <CloseIcon color="red.500" />
            );
          },
        },
        {
          accessor: "releasedOn",
          Header: "Data de Liberação",
          Cell: ({ value, row }: any) => {
            return !value
              ? "---"
              : DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm:ss");
          },
        },
        {
          accessor: "createdAt",
          Header: "Data de Cadastro",
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm:ss");
          },
        },

        {
          Header: "Ações",
          accessor: "actions",
        },
      ] as Column[],
    []
  );

  const actions = (row: Balance) => (
    <ButtonGroup>
      <ShowBalance dado={row} />
    </ButtonGroup>
  );

  // Chakra Color Mode
  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <TableComponent
          columnsData={columns}
          filterable={["customerId", "createdAt"]}
          right={<></>}
          queryFn={findAllBalancesPending}
          queryKey="findAllBalancesPending"
          actions={actions}
          label="Saldo Pendente"
          aggregate
        />
      </SimpleGrid>
    </Box>
  );
}
