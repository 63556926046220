import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  SimpleGrid,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
// Assets
// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import ChargeStatistics from "components/card/ChargeStatistics";
import IconBox from "components/icons/IconBox";
import useAuth from "contexts/useAuth";
import {
  MdAddTask,
  MdAllInbox,
  MdAttachMoney,
  MdBarChart,
  MdCancel,
  MdCheck,
  MdPendingActions,
  MdPix,
} from "react-icons/md";

import { BsCash, BsCreditCard, BsCreditCardFill } from "react-icons/bs";
import {
  dashboard,
  findAllClients,
  findAllClientsRecurrences,
  findAllCompanys,
  findAllTerminals,
} from "services/api.service";
import { maskCurrency } from "utils/number";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import Card from "components/card/Card";

import { useState } from "react";
import { DateTime } from "luxon";
import { useForm } from "react-hook-form";
import FormRemoteSelectInput, {
  SelectOptionType,
} from "components/FormRemoteSelectInput";
import InputForm from "components/InputForm";
import SelectForm from "components/SelectForm";
import { FaBarcode, FaTruckLoading } from "react-icons/fa";
import CustomButton from "components/CustomButton";
import { PiFunnel } from "react-icons/pi";

interface DashboardForm {
  type?: string;
  initialDate: string;
  finalDate: string;
  company: SelectOptionType;
  customer: SelectOptionType;
}

export default function UserReports() {
  // Chakra Color Mode
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { userData } = useAuth();
  const [filter, setFilter] = useState<{
    type?: string;
    initialDate: string;
    finalDate: string;
    companyId?: string;
    customerId?: string;
    maquinetaId?: string;
  }>({
    finalDate: DateTime.local().endOf("month").toFormat("yyyy-MM-dd'T'HH:mm"),
    initialDate: DateTime.local()
      .startOf("month")
      .toFormat("yyyy-MM-dd'T'HH:mm"),
  });

  const { control, handleSubmit } = useForm<DashboardForm>({
    defaultValues: {
      finalDate: DateTime.local().endOf("month").toFormat("yyyy-MM-dd'T'HH:mm"),
      initialDate: DateTime.local()
        .startOf("month")
        .toFormat("yyyy-MM-dd'T'HH:mm"),
    },
  });

  const { data, refetch } = useQuery(
    ["dashboard", filter],

    () => dashboard(filter),
    {}
  );

  const buscar = (data: DashboardForm) => {
    if (DateTime.fromISO(data.initialDate) > DateTime.fromISO(data.finalDate)) {
      toast({
        title: "Erro",
        description: "A data inicial não pode ser maior que a data final",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    if (
      DateTime.fromISO(data.initialDate)
        .diff(DateTime.fromISO(data.finalDate))
        .as("days") < -180
    ) {
      toast({
        title: "Erro",
        description:
          "A diferença entre as datas não pode ser maior que 180 dias",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    const search = {
      type: data.type,
      initialDate: DateTime.fromISO(data.initialDate).toISO(),
      finalDate: DateTime.fromISO(data.finalDate).toISO(),
      companyId: data.company?.value || undefined,
      customerId: data.customer?.value || undefined,
    };
    if (search !== filter) {
      refetch();
    }
    setFilter(search);
  };

  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const percenteProfit = data?.pastMonthLiquid
    ? (data?.amount / (data?.pastMonthLiquid || 1) - 1) * 100
    : 0;

  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <HStack spacing={4} mb={4}>
        <CustomButton leftIcon={<PiFunnel />} onClick={onOpen}>
          Filtrar Dashboard
        </CustomButton>{" "}
        {!userData.twoFa ? (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>Ativar 2FA!</AlertTitle>
            <AlertDescription>
              Por favor, ative o 2FA no menu "Configurações" para ter acesso a
              todas as funcionalidades.
            </AlertDescription>
          </Alert>
        ) : null}
      </HStack>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Filtrar dashboard</DrawerHeader>

          <DrawerBody>
            <VStack spacing={4}>
              <SelectForm
                control={control}
                name="type"
                placeholder="Tipo de Venda"
                label="Tipo da venda:"
                options={[
                  { value: "CREDIT_CARD", label: "Crédito" },
                  { value: "DEBIT_CARD", label: "Débito" },
                  { value: "PIX", label: "Pix" },
                  { value: "TICKET", label: "Boleto" },
                  { value: "CASH", label: "Dinheiro" },
                ]}
              />
              <InputForm
                control={control}
                name="initialDate"
                label="Data inicial:"
                rules={{ required: true }}
                type="datetime-local"
                placeholder="Data inicial"
              />
              <InputForm
                control={control}
                name="finalDate"
                label="Data inicial:"
                rules={{ required: true }}
                type="datetime-local"
                placeholder="Data inicial"
              />
              {!userData.isCustomerAdmin && (
                <FormRemoteSelectInput
                  control={control}
                  name="company"
                  label="Empresa"
                  size="sm"
                  loadDataFn={({ value, cb }) =>
                    findAllCompanys({ filter: value, limit: 10, page: 0 }).then(
                      (retorno) => {
                        if (retorno?.registers?.length > 0) {
                          cb([
                            { label: "Escolher uma opção...", value: "" },
                            ...retorno.registers?.map((d: any) => ({
                              label: d.name,
                              value: d.id,
                            })),
                          ]);
                        } else {
                          cb([]);
                        }
                      }
                    )
                  }
                />
              )}
              {
                <FormRemoteSelectInput
                  control={control}
                  name="customer"
                  size="sm"
                  label="Cliente"
                  loadDataFn={({ value, cb }) =>
                    findAllClients({ filter: value, limit: 10, page: 0 }).then(
                      (retorno) => {
                        if (retorno?.registers?.length > 0) {
                          cb([
                            { label: "Escolher uma opção...", value: "" },
                            ...retorno.registers?.map((d: any) => ({
                              label: d.name,
                              value: d.id,
                            })),
                          ]);
                        } else {
                          cb([]);
                        }
                      }
                    )
                  }
                />
              }
              {
                <FormRemoteSelectInput
                  control={control}
                  name="maquineta"
                  label="Terminal"
                  size="sm"
                  onChange={(e) => {
                    setFilter((prev) => ({ ...prev, maquinetaId: e.value }));
                  }}
                  loadDataFn={({ value, cb }) =>
                    findAllTerminals({
                      filter: value,
                      limit: 10,
                      page: 0,
                      orderBy: [
                        {
                          id: "name",
                          desc: false,
                        },
                      ],
                    }).then((retorno) => {
                      if (retorno?.registers?.length > 0) {
                        cb([
                          { label: "Escolher uma opção...", value: "" },
                          ...retorno.registers?.map((d: any) => ({
                            label: `${d.number} - ${d.name}`,
                            value: d.id,
                          })),
                        ]);
                      } else {
                        cb([]);
                      }
                    })
                  }
                />
              }{" "}
              <FormRemoteSelectInput
                control={control}
                name="promoter"
                label="Promoter"
                size="sm"
                onChange={(e) => {
                  setFilter((prev) => ({
                    ...prev,
                    promoterId: e.value,
                  }));
                }}
                loadDataFn={({ value, cb }) =>
                  findAllClientsRecurrences({
                    filter: value,
                    limit: 10,
                    page: 0,
                  }).then((retorno) => {
                    if (retorno?.registers?.length > 0) {
                      cb([
                        { label: "Escolher uma opção...", value: "" },
                        ...retorno.registers?.map((d: any) => ({
                          label: d.name,
                          value: d.id,
                        })),
                      ]);
                    } else {
                      cb([]);
                    }
                  })
                }
              />
              <Button
                variant="solid"
                rounded="sm"
                minW="120px"
                width={"100%"}
                colorScheme="brand"
                onClick={handleSubmit(buscar)}
              >
                Buscar
              </Button>
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Fechar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <Text fontSize="md" fontWeight="700" mb="5px" ml={5}>
        Resumo de Cobranças
      </Text>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 4, "2xl": 4 }}
        gap="20px"
        mb="20px"
      >
        <ChargeStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={MdPendingActions}
                  color="yellow.400"
                />
              }
            />
          }
          name="Pendentes"
          value={maskCurrency(data?.charges?.totalPending)}
          growth={data?.charges?.pending}
        />
        <ChargeStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdCancel} color={"red.400"} />}
            />
          }
          name="Canceladas"
          value={maskCurrency(data?.charges?.totalCanceled)}
          growth={data?.charges?.canceleds}
        />
        <ChargeStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdCheck} color="green.600" />}
            />
          }
          name="Finalizadas"
          value={maskCurrency(data?.charges?.totalPaids)}
          growth={data?.charges?.paids}
        />{" "}
        <ChargeStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={FaTruckLoading}
                  color={brandColor}
                />
              }
            />
          }
          name="Devoluções"
          value={maskCurrency(data?.charges?.refunds)}
        />
      </SimpleGrid>
      <Text fontSize="md" fontWeight="700" mb="5px" ml={5}>
        Resumo de Vendas
      </Text>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Valor em Vendas"
          value={maskCurrency(data?.amount)}
          growth={percenteProfit}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name="Valor Total Líquido"
          value={maskCurrency(data?.liquid)}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name="Líquido Clientes"
          value={maskCurrency(data?.liquidCustumer)}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
              icon={<Icon w="32px" h="32px" as={MdAllInbox} color="white" />}
            />
          }
          name="Total de vendas"
          value={data?.total}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
              icon={<Icon w="28px" h="28px" as={MdAddTask} color="white" />}
            />
          }
          name="Finalizadas"
          value={data?.fineshed}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg="linear-gradient(120deg, #b22222 0%, #e34234 100%)"
              icon={<Icon w="32px" h="32px" as={MdCancel} color="white" />}
            />
          }
          name="Canceladas"
          value={data?.canceled}
        />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        {/* {<TotalSpent />} */}
        <Card alignItems="center" flexDirection="column" w="100%">
          <Flex align="center" w="100%" py="10px">
            <VStack>
              <Text
                me="auto"
                px="15px"
                color={"gray.800"}
                fontSize="xl"
                fontWeight="700"
                lineHeight="100%"
              >
                Vendas no mês
              </Text>
              <SimpleGrid
                columns={{ base: 1, md: 2, xl: 2 }}
                gap="20px"
                mb="20px"
              >
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={BsCreditCardFill}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  name="Total Cartão de Crédito"
                  sumValue={data?.totalCreditCard}
                  value={data?.creditCard}
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={BsCreditCard}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  name="Total Cartão de Débito"
                  value={data?.debitCard}
                  sumValue={data?.totalDebitCard}
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={BsCash}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  name="Total Dinheiro"
                  value={data?.cash}
                  sumValue={data?.totalCash}
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon w="32px" h="32px" as={MdPix} color={brandColor} />
                      }
                    />
                  }
                  name="Total de Pix"
                  value={data?.pix}
                  sumValue={data?.totalPix}
                />{" "}
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={FaBarcode}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  name="Total de Boleto"
                  value={data?.ticket}
                  sumValue={data?.totalTicket}
                />
              </SimpleGrid>
            </VStack>
          </Flex>
        </Card>
        <WeeklyRevenue data={data?.daily} />
      </SimpleGrid>
      {/* {<SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
          <DailyTraffic />
          <PieCard />
        </SimpleGrid>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
          <Tasks />
          <MiniCalendar h="100%" minW="100%" selectRange={false} />
        </SimpleGrid>
      </SimpleGrid>} */}
    </Box>
  );
}
