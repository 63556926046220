import { useToast } from "@chakra-ui/react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import api, { loginService, updateCustomerService } from "services/api.service";
import jwt_decode from "jwt-decode";

export const AUTHSTORAGE_KEY = `asdasd1as32d1as6d54wq6d132dsg1r`;

type AuthContextType = {
  userData: AuthUserData;
  hasPermission: (...permission: UserPermissions[]) => boolean;
  signIn: (args: {
    username: string;
    history: any;
    twoFa?: string;
    password: string;
    captchaKey: string;
    callbackUrl?: string;
  }) => Promise<AuthUserData>;
  signOut: (history: any) => Promise<void>;
  updateUserData: () => void;
};

export enum UserPermissions {
  PLAN_READ = "plan.read",
  PLAN_WRITE = "plan.write",
  DASHBOARD_READ = "dashboard.read",
  BALANCES_READ = "balances.read",
  BALANCES_WRITE = "balances.write",
  MOVEMENTS_READ = "movements.read",
  MOVEMENTS_WRITE = "movements.write",
  CHARGES_READ = "charges.read",
  CHARGES_CREATE = "charges.create",
  CHARGES_WRITE = "charges.write",
  CUSTOMERS_READ = "customers.read",
  CUSTOMERS_WRITE = "customers.write",
  CUSTOMERS_BALANCE = "customers.balance",
  CUSTOMERS_TRANSFER = "customers.transfer",
  CUSTOMERS_CREATE = "customers.create",
  USERS_READ = "users.read",
  USERS_WRITE = "users.write",
  MAQUINETAS_READ = "maquinetas.read",
  MAQUINETAS_WRITE = "maquinetas.write",
  TERMINAL_READ = "terminais.read",
  TERMINAL_WRITE = "terminais.write",
  WITHDRAWALS_READ = "withdrawals.read",
  WITHDRAWALS_WRITE = "withdrawals.write",
  WITHDRAWALS_AUTOMATIC = "withdrawals.automatic",
  INSURANCES_READ = "insurances.read",
  INSURANCES_WRITE = "insurances.write",
  WALLET_READ = "wallet.read",
  WALLET_WRITE = "wallet.write",
  FEE_READ = "fee.read",
  FEE_WRITE = "fee.write",
  INTEGRATIONS_READ = "integrations.read",
  INTEGRATIONS_WRITE = "integrations.write",
  COMPANYS_READ = "companys.read",
  COMPANYS_WRITE = "companys.write",
}

export enum PermDescriptions {
  "dashboard" = "Dashboard",
  "balances" = "Extrato",
  "movements" = "Vendas",
  "charges" = "Cobranças",
  "customers" = "Clientes",
  "users" = "Usuários",
  "maquinetas" = "Terminais",
  "withdrawals" = "Saque",
  "insurances" = "Seguros",
  "wallet" = "Carteira",
  "fee" = "Tarifas",
  "integrations" = "Integrações",
  "companys" = "Empresas",
  "plan" = "Planos",
  terminais = "Terminais",
}

export enum ReadWrite {
  "read" = "Leitura",
  "write" = "Escrita",
  "balance" = "Saldo",
  "transfer" = "Transferência",
  "create" = "Criação",
  "automatic" = "Automático",
}

export type AuthUserData = {
  isAuth: boolean;
  isCustomerAdmin: boolean;
  authorization: string;
  name: string;
  email: string;
  twoFa: boolean;
  permissions: UserPermissions[];
  id: string;
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export default function useAuth() {
  return useContext(AuthContext);
}

export function getAuthStorage(): AuthUserData {
  if (typeof localStorage === `undefined`) {
    console.log(`localStorage is not defined`);
    return {} as AuthUserData;
  }
  try {
    const localStorageData = localStorage.getItem(AUTHSTORAGE_KEY);

    return localStorageData ? JSON.parse(localStorageData) : null;
  } catch (error) {
    console.error(`localStorage get error`, error);
    return {} as AuthUserData;
  }
}

export function setAuthStorage(value: AuthUserData): void {
  return localStorage.setItem(AUTHSTORAGE_KEY, JSON.stringify(value));
}

export function AuthProvider({ children }: any) {
  const toast = useToast();
  const [userData, setUserData] = useState<AuthUserData>(
    getAuthStorage() || ({} as AuthUserData)
  );

  const signIn = useCallback(
    async ({
      username,
      password,
      history,
      twoFa,
      callbackUrl = `/admin/default`,
      captchaKey,
    }: {
      username: string;
      history: any;
      twoFa?: string;
      password: string;
      callbackUrl?: string;
      captchaKey: string;
    }): Promise<AuthUserData> => {
      const authResponse = await loginService(
        username,
        password,
        captchaKey,
        twoFa
      );
      toast({
        title: "Sucesso",
        description: "Login realizado com sucesso",
        status: "success",
        duration: 5000,
        position: "top",
        isClosable: true,
      });

      history.push(callbackUrl);

      const data = {
        authorization: authResponse.token,
        email: authResponse.email,
        isAuth: true,
        isCustomer: false,
        isCustomerAdmin: !!authResponse.companyId,
        id: authResponse.id,
        name: authResponse.name,
        twoFa: authResponse.twoFa,
        permissions: authResponse.permissions,
      };
      setUserData(data);
      setAuthStorage(data);
      return data;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const signOut = useCallback(async (history: any) => {
    try {
      setUserData({} as AuthUserData);
      setAuthStorage({} as AuthUserData);
      history.push(`/auth`);
    } catch (error) {
      throw error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const updateUserData = useCallback(async () => {
    try {
      const authResponse = await updateCustomerService();

      const data = {
        authorization: authResponse.token,
        email: authResponse.email,
        isAuth: true,
        isCustomer: !!authResponse.customerId,
        isCustomerAdmin: !!authResponse.companyId && !authResponse.customerId,
        id: authResponse.id,
        name: authResponse.name,
        twoFa: authResponse.twoFa,
        permissions: authResponse.permissions,
      };
      setUserData(data);
      setAuthStorage(data);
    } catch (error) {
      throw error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userData.isAuth) {
      const token = jwt_decode(userData.authorization) as any;
      if (token.exp) {
        const now = new Date();
        const exp = new Date(token.exp * 1000);
        if (now > exp) {
          window.location.href = "/#/auth";
        }
      }
      localStorage.setItem("Authorization", userData.authorization);
      api.defaults.headers.Authorization = "Bearer " + userData.authorization;
    } else {
      window.location.href = "/#/auth";
    }
  }, [userData]);

  const hasPermission = useCallback(
    (...permission: UserPermissions[]) => {
      return permission.some((perm) => userData.permissions?.includes(perm));
    },
    [userData]
  );

  return (
    <AuthContext.Provider
      value={{
        userData,
        signIn,
        signOut,
        updateUserData,
        hasPermission,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
