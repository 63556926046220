import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  HStack,
  Button,
  useToast,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import SwithForm from "components/SwithForm";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { VscSettings } from "react-icons/vsc";
import { saveFeatures } from "services/api.service";

export interface CustomerFeatures {
  API: boolean;
  PIX: boolean;
  CREDIT_CARD: boolean;
  BILLET: boolean;
  INVOICE_API: boolean;
  PIX_API: boolean;
  WITHDRAW_API: boolean;
  CREDIT_CARD_API: boolean;
  MERCADO_PAGO_API: boolean;
  BILLET_API: boolean;
  CONFIGURE_AUTOMATIC_WITHDRAW: boolean;
}

const FeaturesCustomers: React.FC<{
  customerId: string;
  features?: CustomerFeatures;
  refetch: () => {};
}> = ({ customerId, features, refetch }) => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleSubmit, control, reset, setValue } = useForm<CustomerFeatures>({
    defaultValues: {
      API: false,
      PIX: false,
      CREDIT_CARD: false,
      BILLET: false,
      INVOICE_API: false,
      PIX_API: false,
      WITHDRAW_API: false,
      CREDIT_CARD_API: false,
      MERCADO_PAGO_API: false,
      BILLET_API: false,
    },
  });
  const save = async (features: CustomerFeatures) => {
    setLoading(true);
    saveFeatures(customerId, features)
      .then(() => {
        onClose();
        refetch();
        toast({
          title: "Sucesso",
          description: "Cliente atualizado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao atualizar cliente",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (features) {
      setValue("API", features.API);
      setValue("PIX", features.PIX);
      setValue("CREDIT_CARD", features.CREDIT_CARD);
      setValue("BILLET", features.BILLET);
      setValue("INVOICE_API", features.INVOICE_API);
      setValue("PIX_API", features.PIX_API);
      setValue("WITHDRAW_API", features.WITHDRAW_API);
      setValue("CREDIT_CARD_API", features.CREDIT_CARD_API);
      setValue("MERCADO_PAGO_API", features.MERCADO_PAGO_API);
      setValue("BILLET_API", features.BILLET_API);
      setValue("CONFIGURE_AUTOMATIC_WITHDRAW", features.CONFIGURE_AUTOMATIC_WITHDRAW);
    }
  }, [isOpen, features, setValue]);

  return (
    <>
      <Button
        onClick={() => {
          onOpen();
          reset();
        }}
        size="sm"
        rounded="sm"
        variant="outline"
        colorScheme="brand"
      >
        <VscSettings />
        <Text ml={2}>Funcionalidades</Text>
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Editar Features</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={3}>
                <SwithForm control={control} name="API" label="API" />
                <SwithForm
                  control={control}
                  name="BILLET"
                  label="Recebimento por Boletos"
                />
                <SwithForm
                  control={control}
                  name="PIX"
                  label="Recebimento por Pix"
                />
                <SwithForm
                  control={control}
                  name="CREDIT_CARD"
                  label="Recebimento por Cartão de Crédito"
                />
                <SwithForm
                  control={control}
                  name="INVOICE_API"
                  label="API de Cobranças"
                />
                <SwithForm
                  control={control}
                  name="PIX_API"
                  label="API de Pix"
                />
                <SwithForm
                  control={control}
                  name="WITHDRAW_API"
                  label="API de Saques"
                />
                <SwithForm
                  control={control}
                  name="CREDIT_CARD_API"
                  label="API de Cartão de Crédito"
                />
                <SwithForm
                  control={control}
                  name="MERCADO_PAGO_API"
                  label="API de Mercado Pago"
                />
                <SwithForm
                  control={control}
                  name="BILLET_API"
                  label="API de Boletos"
                />
                <SwithForm
                  control={control}
                  name="CONFIGURE_AUTOMATIC_WITHDRAW"
                  label="Saques Automáticos SubConta"
                />
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(save)}
                >
                  Salvar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default FeaturesCustomers;
