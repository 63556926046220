import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  HStack,
  Button,
  useToast,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import FormRemoteSelectInput, {
  SelectOptionType,
} from "components/FormRemoteSelectInput";
import InputForm from "components/InputForm";
import InputNumber from "components/InputNumber";
import SelectForm from "components/SelectForm";
import { queryClient } from "index";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa";
import { createRecurrency, findAllClients } from "services/api.service";

enum Period {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

export type CreateRecurrencyForm = {
  amount: string;
  description: string;
  initDate: string;
  period: string;
  endDate: string;
  customer: SelectOptionType;
};

const CreateRecurrence: React.FC<{}> = () => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleSubmit, control, reset } = useForm<CreateRecurrencyForm>();

  const save = async (dataForm: CreateRecurrencyForm) => {
    setLoading(true);
    debugger
    createRecurrency({
      amount: dataForm.amount,
      description: dataForm.description,
      endDate: dataForm.endDate,
      initDate: dataForm.initDate,
      period: dataForm.period,
      customerId: dataForm.customer.value,
    })
      .then(() => {
        onClose();
        queryClient.refetchQueries({
          queryKey: ["findAllRecurrencys"],
          type: "active",
        });
        toast({
          title: "Sucesso",
          description: "Recorrência adicionado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao criar Recorrência",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    reset({});
  }, [reset]);

  return (
    <>
      <Button
        onClick={() => {
          onOpen();
          reset();
        }}
        size="sm"
        rounded="sm"
        variant="outline"
        colorScheme="brand"
      >
        <FaPlus />
        <Text ml={2}>Criar Recorrência</Text>
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Criar Recorrência</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={3}>
                <FormRemoteSelectInput
                  control={control}
                  name="customer"
                  label="Cliente"
                  loadDataFn={({ value, cb }) =>
                    findAllClients({ filter: value, limit: 10, page: 0 }).then(
                      (retorno) => {
                        if (retorno?.registers?.length > 0) {
                          cb([
                            { label: "Escolher uma opção...", value: "" },
                            ...retorno.registers?.map((d: any) => ({
                              label: d.name,
                              value: d.id,
                            })),
                          ]);
                        } else {
                          cb([]);
                        }
                      }
                    )
                  }
                />
                <InputForm
                  control={control}
                  name="description"
                  label="Nome da Recorrência *"
                  type="text"
                  rules={{
                    required: "Campo obrigatório",
                  }}
                />
                <InputNumber
                  control={control}
                  name="amount"
                  label="Valor *"
                  rules={{
                    required: "Campo obrigatório",
                  }}
                />
                <SelectForm
                  control={control}
                  name="period"
                  rules={{
                    required: "Campo obrigatório",
                  }}
                  label="Período *"
                  options={[
                    { label: "Selecione", value: "" },
                    { label: "Diário", value: Period.DAILY },
                    { label: "Semanal", value: Period.WEEKLY },
                    { label: "Mensal", value: Period.MONTHLY },
                    { label: "Anual", value: Period.YEARLY },
                  ]}
                />
                <InputForm
                  control={control}
                  name="initDate"
                  label="Data Inicial *"
                  type="date"
                  rules={{
                    required: "Campo obrigatório",
                  }}
                />
                <InputForm
                  control={control}
                  name="endDate"
                  label="Data Final *"
                  type="date"
                  rules={{
                    required: "Campo obrigatório",
                  }}
                />
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(save)}
                >
                  Adicionar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default CreateRecurrence;
